import React from "react"

import Layout from "../components/layout"

export default () => (
  <Layout pageTitle="Not Found">
    <h2>404 Not Found</h2>
    <p>
      あなたは真に驚くべきページを見つけましたが、ここに書くには余白が狭すぎます——
    </p>
  </Layout>
)
